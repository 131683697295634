var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"d-flex justify-space-between align-center mx-2"},[_c('page-header',{attrs:{"title":_vm.title,"menu":_vm.menu}}),_c('internal-menu',{attrs:{"menuItems":_vm.menuItems}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Excluir Registro ")]),_c('v-card-text',[_vm._v("Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO Será Possível REVERTER")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" CANCELAR ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteItem}},[_vm._v(" EXCLUIR ")])],1)],1)],1),_c('v-snackbar',{attrs:{"bottom":_vm.snackbar.y === 'bottom',"color":_vm.snackbar.color,"left":_vm.snackbar.x === 'left',"multi-line":_vm.snackbar.mode === 'multi-line',"right":_vm.snackbar.x === 'right',"timeout":_vm.snackbar.timeout,"top":_vm.snackbar.y === 'top',"vertical":_vm.snackbar.mode === 'vertical'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar.opened = false}}},'v-btn',attrs,false),[_vm._v("Ok")])]}}]),model:{value:(_vm.snackbar.opened),callback:function ($$v) {_vm.$set(_vm.snackbar, "opened", $$v)},expression:"snackbar.opened"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-toolbar',{attrs:{"elevation":"0","height":"80"}},[_c('v-row',{staticClass:"d-flex justify-space-around align-center"},[_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('v-text-field',{attrs:{"filled":"","outlined":"","rounded":"","append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"width":"80%","color":"#2ca01c","to":"/pedbem/product/register","dark":""}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" Novo Produto")],1),_c('v-btn',{staticClass:"hidden-md-and-up",attrs:{"dark":"","color":"#2ca01c","to":"/pedbem/product/register"}},[_c('v-icon',[_vm._v("mdi-plus-thick")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.products,"search":_vm.search},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("R$ "+_vm._s(_vm.formatPrice(item.price)))])]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.stockControl ? item.stock : "Ilimitado"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","to":("/pedbem/product/" + (item.id) + "/edit")}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteOpen(item.id)}}},[_vm._v("mdi-eraser")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }